import { Autocomplete as MUIAutocomplete, Switch, SwitchProps } from "@mui/material";
import styled from "styled-components";

export const MainContainer = styled.div`
    margin: 64px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

export const Title = styled.header`
    width: 164px;
    height: 37px;
    margin-bottom: 12px;
    font-family: IBM Plex Sans;
    font-size: 28px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    white-space: nowrap;
`;

export const SwitchBoxStyled = styled.div`
    display: flex;
    flex: 0;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
`;

export const SwitchLabelStyled = styled.span`
    flex: 1;
    white-space: nowrap;
    font-family: IBM Plex Sans;
`;

export const SwitchButton = styled(Switch)<SwitchProps>`
    && {
        .Mui-checked {
            color: ${({ theme }) => theme.colors.primary};
        }
        .MuiSwitch-track {
            background-color: "#00000061";
        }
        .MuiSwitch-switchBase {
            color: ${({ checked, theme }) => (checked ? theme.colors.primary : theme.colors.white)};
        }
    }

    margin: auto;
`;

export const uploadFileInput = styled.input`
    type="file"
`;

export const AutoCompleteSized = styled(MUIAutocomplete)`
  & ..MuiAutocomplete-input {
    padding: 2.5px 2px 2.5px 8px !important;
  }
`;
