import { Button, ButtonProps, Input, Table, TableRow } from "@mui/material";
import styled from "styled-components";

export const MainContainer = styled.div`
    margin: 0 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

export const StyledTable = styled(Table)`
    position: relative;
    thead tr {
        border-top: 1px solid rgba(224, 224, 224, 1) !important;
    }
    tbody td.role {
        text-transform: capitalize;
    }
    th {
        text-transform: capitalize;
        font-weight: 700;
        text-align: center;

        &.left-aligned {
            text-align: left;
        }
        &.right-aligned {
            text-align: right;
        }
    }

    td {
        text-align: center;

        &.left-aligned {
            text-align: left;
        }
        &.right-aligned {
            text-align: right;
        }
        &.no-border {
            border: none;
        }

        div.multi-cell {
            display: flex;
            justify-content: center;
        }
    }

    @media (max-width: 450px) {
        td {
            max-width: 80px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: pre;
        }
    }
`;

export const LoaderContainer = styled.div`
    margin-top: 50px;
`;

export const StyledTableRow = styled(TableRow)`
    display: flex !important;
    justify-content: flex-end !important;
    td {
        padding-right: 0px !important;
    }
`;

export const HeaderTableContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: none;
    flex-wrap: wrap;

    && {
        .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
            padding-left: 0
        }
    }
`;
export const StyledSearchInput = styled(Input)`
    font-size: 14px !important;
    margin: 0 16px 16px 16px;
`;

export const EmptyList = styled.div`
    display: flex;
    margin: 50px auto;
    justify-content: center;
`;

export const LoadingGradient = styled.div`
    margin: -44px -46px -40px;
    width: 273px;
    height: 236px;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loadingAnimation;
    animation-timing-function: linear;
    position: relative;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.08));
    background-repeat: no-repeat;
    @keyframes loadingAnimation {
        0% {
            background-position: -546px 0;
        }
        100% {
            background-position: 546px 0;
        }
    }
`;

export const Description = styled.div`
    font-family: IBM Plex Sans;
    font-size: 18px;
`;

export const RedirectButton = styled(Button).attrs((prop) => ({
    color: prop.color ? prop.color : "primary",
    variant: "contained"
} as ButtonProps))`
&& {
height: 38px;
padding: 10px 16px;
box-sizing: border-box;
border-radius: 4px;
font-family: IBM Plex Sans;
font-size: 14px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: 1.29;
letter-spacing: normal;
text-align: center;
margin: 0 auto;
}
`;


export const HeaderTableFilterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
`;

export const FilerSpan = styled.span`
    display: flex;
    margin: auto;
`;

export const FilterInput = styled.input`
    display: flex;
    border: none;
    margin: auto 0 auto 7px;
    width: 200px;
    background: none;

    &:focus {
        outline: none;
    }
`;
