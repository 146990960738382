import React from "react";
import { MainContainer, PageContainer } from "./styled";
import { Box, AppBar, CssBaseline } from '@mui/material';
import Drawer from "components/Drawer"
import PanelHeader from "components/PanelHeader";
import { TrsansactionsHistory } from "components/Logs/Logs";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toastError } from "components/Toastify";

interface LogsProps {
    apiServerUrl: string
    isApiServerConnected: boolean
}

export function Logs({ apiServerUrl, isApiServerConnected }: LogsProps): React.ReactElement {
    const { t } = useTranslation();
    
    if (!isApiServerConnected) {
        toastError(t("transactions:not.connected.error"));
        return <Redirect to="/" />;
    }
    
    return (
        // eslint-disable-next-line react/forbid-component-props
        <PageContainer className="resizeHeader">
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />

                <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <PanelHeader
                        apiServerUrl={apiServerUrl}
                        isApiServerConnected={isApiServerConnected}
                     />
                </AppBar>

                <Drawer />

                <Box component="main" sx={{ flexGrow: 1, p: 3, paddingTop: "64px", height: "100vh" }}>
                    <MainContainer>
                        <TrsansactionsHistory
                            apiServerUrl={apiServerUrl}
                            isApiServerConnected={isApiServerConnected}
                        />
                    </MainContainer>
                </Box>
            </Box>
        </PageContainer>
    );
}
