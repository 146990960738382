import styled from "styled-components";

export const PageContainer = styled.div`
    background-color: ${(props) => props.theme.colors.background};
    width: 100%;
    height: 100%;
    position: relative;
`;

export const MainContainer = styled.div`
    margin-top: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;
